import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

import { clsx } from 'clsx'
import IndexLoansLogo from '../../assets/logos/index-loans-logo.svg'
import { Button } from '../foundation/Button'
import { LoanApplicationPath, MakePaymentPath, NavbarItems } from './navigationItems'
import shouldShowApplicationFlow from '../../utils/shouldShowApplicationFlow'

interface NavbarProps {
  sticky?: boolean
}

const Navbar = ({ sticky }: NavbarProps) => {
  return (
    <Disclosure
      as="nav"
      className={clsx({ 'sticky top-0 z-50': sticky }, 'bg-white text-dark shadow-navBar')}
    >
      {({ open }) => (
        <div className="container">
          <div
            className="m-auto flex h-20 min-h-20 w-full items-center justify-between md:h-[100px]"
            aria-label="Global"
          >
            <div className="flex-none">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Index Loans</span>
                <img
                  className="h-8 w-auto md:h-[52px]"
                  src={IndexLoansLogo.src}
                  alt="index-loans-installment-loans-online"
                  width={152}
                  height={52}
                />
              </a>
            </div>
            <div className="flex flex-1 items-center justify-end">
              <div className="hidden pr-11 lg:flex lg:gap-x-11">
                {NavbarItems.map((item) => (
                  <a
                    key={item.label}
                    href={item.path}
                    className="text-center text-custom-lg leading-6 before:invisible before:block before:h-0 before:overflow-hidden before:font-semibold before:content-[attr(title)] hover:font-semibold hover:text-primary_mint"
                    title={item.label}
                  >
                    {item.label}
                  </a>
                ))}
              </div>
              <div className="flex md:gap-x-6 lg:justify-end lg:gap-x-3">
                <Button
                  variant="primary"
                  color="white"
                  className="hidden lg:block"
                  href={MakePaymentPath}
                >
                  Make a Payment
                </Button>
                {shouldShowApplicationFlow() && (
                  <Button
                    href={LoanApplicationPath}
                    variant="primary"
                    color="darkgreen"
                    className="hidden md:block"
                  >
                    Apply Now
                  </Button>
                )}
                <div className="flex lg:hidden">
                  <Disclosure.Button className="text-gray-700 -m-2.5 inline-flex items-center justify-center rounded-md p-2.5">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
          </div>
          <div
            className={clsx(
              { block: open, hidden: !open },
              'absolute left-0 z-[51] h-[2px] w-full bg-white shadow-navBar'
            )}
          />
          <Disclosure.Panel
            className={`absolute left-0 z-50 h-screen w-full bg-white px-2 md:px-7 lg:hidden ${
              open ? 'block' : 'hidden'
            }`}
          >
            <div className="space-y-1 pb-3 pt-11">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              {NavbarItems.map((item) => (
                <Disclosure.Button
                  key={item.label}
                  as="a"
                  href={item.path}
                  className="block pb-11 pl-6 text-2xl hover:font-semibold hover:text-primary_mint md:pb-[72px] md:pl-11"
                >
                  {item.label}
                </Disclosure.Button>
              ))}

              <div className="mt-3 space-y-4 px-6 md:pl-11">
                {shouldShowApplicationFlow() && (
                  <Button
                    href={LoanApplicationPath}
                    variant="primary"
                    color="darkgreen"
                    className="h-16 w-full !rounded-3xl text-custom-lg md:hidden"
                  >
                    Apply Now
                  </Button>
                )}
                <Button
                  href={MakePaymentPath}
                  variant="primary"
                  color="white"
                  className="h-16 w-full !rounded-3xl text-custom-lg"
                >
                  Make a Payment
                </Button>
              </div>
            </div>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}

export default Navbar
